<template>
  <div>
    <InputWrapper
      v-if="showIntensity"
      type="number"
      :label="'Intensity'"
      :value="intensity"
      :short="true"
      :min="intensityMin"
      :step="intensityStep"
      @change="onChangeIntensity"
    />

    <InputWrapper
      type="color"
      :label="'Color'"
      :value="color"
      :short="true"
      @change="onChangeColor"
    />

    <InputWrapper
      v-if="showPosition"
      type="position"
      :label="'Position'"
      :values="position"
      @change="onChangePosition"
    />

    <InputWrapper
      v-if="showRotation"
      type="position"
      :label="'Rotation'"
      :values="rotation"
      @change="onChangeRotation"
    />
  </div>
</template>

<script>
import mixin from '../NodeSettingMixin'

export default {
  name: 'GeneralLightBlock',

  mixins: [mixin],

  data () {
    return {
      intensityMin: 0,
      intensityStep: 0.1
    }
  },

  computed: {
    showPosition () {
      return this.node.type !== 'AmbientLight'
    },

    showRotation () {
      return false
    },

    showIntensity () {
      return true // All lights have an intensity
    },

    showColor () {
      return true // All lights have a color
    },

    intensity () {
      return this.node.intensity
    },

    color () {
      return '#' + this.node.color.getHexString()
    },

    position () {
      return {
        x: this.node.position.x,
        y: this.node.position.y,
        z: this.node.position.z
      }
    },

    rotation () {
      return {
        x: this.node.rotation.x,
        y: this.node.rotation.y,
        z: this.node.rotation.z
      }
    }
  },

  methods: {

    onChangePosition (values) {
      this.gui.updateNode(() => {
        if (values.x !== undefined) this.node.position.x = values.x
        if (values.y !== undefined) this.node.position.y = values.y
        if (values.z !== undefined) this.node.position.z = values.z
      })
    },

    onChangeRotation (values) {
      this.gui.updateNode(() => {
        if (values.x !== undefined) this.node.rotation.x = values.x
        if (values.y !== undefined) this.node.rotation.y = values.y
        if (values.z !== undefined) this.node.rotation.z = values.z
      })
    },

    onChangeIntensity (value) {
      this.gui.updateNode(() => {
        this.node.intensity = value
      })
    },

    onChangeColor (value) {
      this.gui.updateNode(() => {
        value = value.replace('#', '0x')
        this.node.color.setHex(value)
      })
    }
  }
}
</script>
